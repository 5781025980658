import { useState } from 'react';
import Home from './components/Home/Home';
import useAnim from './components/Hooks/useAnim';
import Links from './components/Links/Links';
import Projects from './components/Projects/Projects';
import { Info } from './data/Data';
import './styles/Sidoobee.scss';

function Sidoobee() {
  const [dark, setDark] = useState(false);
  useAnim('fade');
  useAnim('slide');
  return (
    <div className={'app fade ' + (dark && 'dark')}>
      <svg className='toggle' onClick={() => setDark(!dark)}>
        <path d={Info.beeicon} />
      </svg>
      <video src='video/bees.mp4' muted autoPlay loop />
      <Home />
      <Projects />
      <Links />
    </div>
  );
}

export default Sidoobee;
