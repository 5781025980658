import '../../styles/Projects.scss';
import { Discography } from '../../data/Data';

function Projects() {
  return (
    <div className='projects fade'>
      <ul>
        {Discography.map((item) => (
          <li key={item.id} className='slide'>
            <a
              className={item.soon ? 'disabled' : ''}
              href={item.url}
              target='_blank'
              rel='noreferrer'
            >
              {item.title}
            </a>
            <img className='art' src={item.art} alt='' />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Projects;
