import React from 'react';
import ReactDOM from 'react-dom';
import Sidoobee from './Sidoobee';

ReactDOM.render(
  <React.StrictMode>
    <Sidoobee />
  </React.StrictMode>,
  document.getElementById('root')
);
