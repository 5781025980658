import '../../styles/Home.scss';
import { Info } from '../../data/Data';
export default function Home() {
  return (
    <div className='home'>
      <div className='imgContainer'>
        <img className='bee fade' src={Info.bee} alt='' />
        <img className='logo fade' src={Info.logo} alt='' />
      </div>
      <div className='latest slide'>
        <iframe
          title='latest'
          scrolling='no'
          frameBorder='none'
          allow='autoplay'
          src={Info.embed}
        />
      </div>
    </div>
  );
}
