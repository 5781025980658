import { Socials } from '../../data/Data';
import '../../styles/Links.scss';

function Links() {
  return (
    <div className='links fade'>
      <h2>Links</h2>
      <ul>
        {Socials.map((item) => (
          <li key={item.id} className='slide'>
            <a href={item.url} target='_blank' rel='noreferrer'>
              {item.platform}
            </a>
            <svg viewBox='-10 0 35 35'>
              <path d={item.icon} />
            </svg>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Links;
